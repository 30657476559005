<template>
  <div>
    <!-- 切换分类 -->
    <div class="category-bar flex-row-between-center">
      <div :class="currentCategoryIndex === index ? 'category-active category-btn' : 'category-btn'" v-for="(item, index) in categories" :key="index" @click="handleChangeCategory(index)">{{ item.name }}</div>
    </div>

    <!-- 搜索栏 -->
    <search-bar class="search-bar" v-model="keyword" @search="handleSearch"></search-bar>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项 -->
      <div class="list-item" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(item.memberInfoExchangeID)">
        <div class="flex-row-default-center" v-if="item.addedBy && item.addedBy.companyName !== ''">
          <span class="iconfont icon-company"></span>
          <span class="company-name">{{ item.addedBy.companyName }}</span>
        </div>
        <div class="title one-line">{{ item.title }}</div>
        <div class="content three-lines">{{ item.content }}</div>
        <!-- 时间、阅读 -->
        <div class="relevant-info flex-row-default-center">
          <!-- 时间 -->
          <div class="flex-center-center">
            <span class="iconfont icon-time"></span>
            <span class="one-line">{{item.createdAt.substring(0, 10)}}</span>
          </div>

          <!-- 阅读 -->
          <div class="flex-center-center">
            <span class="iconfont icon-read"></span>
            <span class="read-count one-line">{{item.readCount}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
    <!-- 添加信息 -->
    <div class="add">
      <span class="iconfont icon-add" @click="handleAdd"></span>
    </div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import SearchBar from '@/components/SearchBar'

export default {
  name: 'ExchangeInfoList',

  data () {
    return {
      currentCategoryIndex: 0,
      currentCategory: 'Recruitment',
      categories: [
        { name: '招聘', category: 'Recruitment' },
        { name: '项目合作', category: 'ProjectCooperation' },
        { name: '活动信息', category: 'ActivityInfo' },
        { name: '其它', category: 'Other' }
      ],
      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  components: { SearchBar },

  mixins: [page],

  mounted () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 40 - 32) / 120)
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    // 获取数据
    getData () {
      this.api.getAllMemberExchangeInfo({ currentPage: this.currentPage, pageSize: this.pageSize, category: this.currentCategory, keyword: this.keyword }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 切换分类
    handleChangeCategory (index) {
      if (this.currentCategoryIndex === index) return
      this.currentCategoryIndex = index
      this.currentCategory = this.categories[index].category
      this.keyword = ''
      this.initPage()
      this.getData()
    },
    // 查看详情
    handleSwitchPage (id) {
      this.$router.push({ path: '/member-exchange-info-detail', query: { id } })
    },
    // 添加
    handleAdd () {
      this.$router.push('/add-member-exchange-info')
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getData()
    },
    // 搜索
    handleSearch () {
      this.initPage()
      this.getData()
    }
  }
}
</script>

<style scoped>
.search-bar {
  margin: 0 5vw 15px 5vw;
}
.list {
  margin-bottom: 15px;
  padding: 0 5vw;
}
.list-item {
  width: 90vw;
  padding: 15px;
  background: #fff;
  border-radius: 3px;
}
.list-item + .list-item {
  margin-top: 15px;
}

.icon-company {
  font-weight: normal;
}
.company-name {
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
}
.title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color);
}
.content {
  font-size: 15px;
}
.relevant-info {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  color: #aaa;
}
.relevant-info > div:first-child {
  margin-right: 10px;
}
.read-count {
  max-width: calc(100vw - 10vw - 60px - 100px);
}
.icon-time, .icon-read {
  margin-right: 3px;
}

.add {
  position: fixed;
  right: 40px;
  bottom: 100px;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 6px #888;
}
.icon-add {
  font-size: 60px;
  position: fixed;
  right: 35px;
  bottom: 92px;
  color: var(--main-color);
}
</style>
